<template>
  <div style="height: 100%">
    <div class="bpr-clashes">
      <div class="bpr-clashes__header">Últimos Enfrentamientos</div>
      <div class="bpr-clashes__filtercont">
        <RadioPlayingAs
          :is-mobile="isMobileRadio"
          :is-question-visible="false"
          :section="'ultimos enfrentamientos'"
          is-last-clashes
        />
      </div>

      <template v-if="Object.keys(getClashes).length > 0">
        <template v-for="(game, i) in getClashes">
          <div :key="i" class="bpr-clashes__itemgame">
            <div class="bpr-clashes__itemgame__gameinfo">
              <div class="bpr-clashes__itemgame__gameinfo__shieldhome">
                <img :src="game.homeTeam" width="36" />
              </div>
              <div class="bpr-clashes__itemgame__gameinfo__score">{{ game.score }}</div>
              <div class="bpr-clashes__itemgame__gameinfo__shieldaway">
                <img :src="game.awayTeam" width="36" />
              </div>
            </div>
            <div class="bpr-clashes__itemgame__seasonname">
              <span class="valing">{{ game.season }}</span>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="empty-data">
          <p><span class="bold">No existe historial previo </span><span>entre estos equipos</span></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'CBetPerResultClashes',
  props: {
    gameId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  components: {
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
  },
  watch: {
    gameId: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue) {
            await this.getClashesPerGame(newValue);
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', ['getClashes']),
    isMobileRadio() {
      return this.displayWidth < 3000;
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getClashesPerGame']),
  },
};
</script>
<style scoped lang="scss">
.valing {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.bpr-clashes {
  width: 345px;
  height: 100%;
  margin: 0 auto;
  @media screen and (max-width: 813px) {
    width: 297px;
  }
  @media screen and (max-width: 663px) {
    width: 246px;
  }
  @media screen and (max-width: 563px) {
    width: 345px;
  }
  @media screen and (max-width: 363px) {
    width: 313px;
  }
  @media screen and (max-width: 313px) {
    width: 280px;
  }
  &__header {
    width: 345px;
    height: 28px;
    border-radius: 6px;
    background-color: #132839;
    line-height: 30px;
    font-family: 'Roboto-Medium';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 813px) {
      width: 297px;
    }
    @media screen and (max-width: 663px) {
      width: 246px;
    }
    @media screen and (max-width: 563px) {
      width: 345px;
    }
    @media screen and (max-width: 363px) {
      width: 313px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
  }
  &__subheadertext {
    width: 157px;
    margin: 0 auto;
    height: 15px;
    margin-top: 10px;
    font-family: 'Roboto-Medium';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #858f98;
  }
  &__filtercont {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-top: 6px;
    border-radius: 6px;
  }
  &__itemgame {
    width: 284px;
    height: 36px;
    margin: 0 auto;
    margin-top: 12px;
    display: inline-flex;
    @media screen and (max-width: 663px) {
      width: 236px;
    }
    @media screen and (max-width: 563px) {
      width: 284px;
    }
    &__gameinfo {
      width: 128px;
      height: 36px;
      display: inline-flex;
      @media screen and (max-width: 663px) {
        width: 134px;
      }
      @media screen and (max-width: 563px) {
        width: 128px;
      }
      &__shieldhome {
        width: 56px;
        @media screen and (max-width: 663px) {
          width: 36px;
        }
        @media screen and (max-width: 563px) {
          width: 56px;
        }
        img {
          float: left;
        }
      }
      &__shieldaway {
        width: 56px;
        height: 36px;
        @media screen and (max-width: 663px) {
          width: 36px;
        }
        @media screen and (max-width: 563px) {
          width: 56px;
        }
        img {
          float: right;
        }
      }
      &__score {
        width: 41px;
        height: 36px;
        font-size: 20px;
        font-family: 'Roboto-Bold';
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        white-space: nowrap;
      }
    }
    &__seasonname {
      width: 156px;
      height: 36px;
      font-size: 15px;
      font-weight: normal;
      font-family: 'Roboto-Medium';
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      text-align: center;
    }
  }
  &__footer {
    width: 281px;
    height: 30px;
    margin: 0 auto;
    margin-top: 21px;
    background: #fff;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #6b6c6c;
    font-family: 'Roboto-Medium';
    &__color {
      font-family: 'Roboto-Black';
      color: #132839;
    }
    @media screen and (max-width: 563px) {
      display: inline-block;
    }
  }
}
.empty-data {
  height: 85%;
  min-height: 358px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  border: solid 1px #000;
  font-family: Heebo, sans-serif;
  font-size: 23.9px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: center;
  color: #353b51;
  margin-top: 6px;
  padding: 12px;

  & span.bold {
    font-weight: bold;
  }
}
</style>
